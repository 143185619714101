import React from "react"
import { Helmet } from "react-helmet"
import "../style.css"
import Appbar from "../components/appbar"
import Brands from "../components/brands"
import Footer from "../components/footer"

export default function About() {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>About | Zey Ventures</title>
      </Helmet>
      <Appbar />
      <div className="bg-secondary-light ">
        <div className="container mx-auto py-8 px-4 lg:py-16">
          <h1 className="max-w-4xl mb-4 lg:mb-6 text-3xl lg:text-5xl font-serif">
            Our Mission
          </h1>

          <div className="text-gray-800 lg:w-3/5">
            <p className="text-sm md:text-base leading-loose mb-4 lg:mb-6">
              The mission of ZeyVentures and its subsidiaries is to provide
              amazingly high-quality online experiences. As trite as it sounds,
              we believe in the power of the internet to make lives better
              through the distribution of information.
            </p>
            <p className="text-sm md:text-base leading-loose">
              ZeyVentures' assets all focus on providing expert's opinions to
              everyone. We also focus on creating technical assets that are
              performant and provide great experience for even those who don't
              have the latest tech and fastest wi-fi.
            </p>
          </div>
        </div>
      </div>
      <div className="container mx-auto py-8 px-4 lg:py-16">
        <div className="lg:w-3/5">
          <h2 className="text-2xl lg:text-4xl font-serif mb-4 lg:mb-8">
            History Of ZeyVentures
          </h2>
          <p className="text-sm md:text-base leading-loose mb-4 lg:mb-6">
            ZeyVentures is founded by Tyler Zey and Sophia Zey.{" "}
            <a
              className="text-primary underline"
              target="_blank"
              rel="noopener noreferrer"
              href="https://tylerzey.com"
            >
              Tyler Zey
            </a>{" "}
            is an expert in the online marketing, content creation, and digital
            space. He founded{" "}
            <a
              className="text-primary underline"
              target="_blank"
              rel="noopener noreferrer"
              href="https://easyagentpro.com"
            >
              Easy Agent Pro
            </a>{" "}
            in 2015 and in 2019 it was acquired.
          </p>
          <p className="text-sm md:text-base leading-loose mb-4 lg:mb-6">
            After seeing success in the Real estate marketing and content space,
            Tyler and{" "}
            <a
              className="text-primary underline"
              target="_blank"
              rel="noopener noreferrer"
              href="https://sophiazey.com"
            >
              Sophia Zey
            </a>{" "}
            decided to team up with their expertise to create an online
            syndicate for high-quality content.
          </p>
          <p className="text-sm md:text-base leading-loose mb-4 lg:mb-6">
            ZeyVentures is the parent company of several high-quality content
            sites and online digital assets.
          </p>
        </div>
      </div>
      <Brands />
      <Footer />
    </div>
  )
}
