import React from "react"
import Image from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

export default function Brands() {
  const { family, bizware, justtrees, mrtoolpro, music } = useFamilyPetLover()
  // console.log(family.childImageSharp.fixed)
  return (
    <section className="pb-8 px-4">
      <div className="container mx-auto lg:max-w-2xl text-center">
        <h2
          data-sal="slide-up"
          data-sal-duration="800"
          data-sal-easing="ease"
          className="text-2xl lg:text-4xl font-serif mb-4 lg:mb-8"
        >
          Our Brands
        </h2>
        <p
          data-sal="slide-up"
          data-sal-duration="800"
          data-sal-easing="ease"
          className="text-gray-700 mb-8 lg:mb-16"
        >
          Our collection of review and blog websites continues to expand to
          cover a variety of industries and consumer interests. Ranging in topic
          from budget-friendly petcare to the best trees to plant for privacy,
          we have a large target audience.
        </p>
      </div>
      <div className="container mx-auto lg:max-w-3xl">
        <div
          data-sal="fade"
          data-sal-delay="100"
          data-sal-duration="800"
          data-sal-easing="ease"
          className="flex flex-wrap justify-center items-center"
        >
          <div className="p-4 lg:p-8 flex-initial w-1/2 lg:w-1/3">
            <a
              href="https://familypetlover.com"
              className="block transition hover:zoom"
              target="_blank"
              rel="noopener noreferrer"
              title="Family Pet Lover"
            >
              <Image fluid={family.childImageSharp.fluid} />
            </a>
          </div>
          <div className="p-4 lg:p-8 flex-initial w-1/2 lg:w-1/3">
            <a
              href="https://justbizware.com"
              className="block transition hover:zoom"
              target="_blank"
              rel="noopener noreferrer"
              title="Just Bizware"
            >
              <Image className="" fluid={bizware.childImageSharp.fluid} />
            </a>
          </div>
          <div className="p-4 lg:p-8 flex-initial w-1/2 lg:w-1/3">
            <a
              href="https://justtreesonline.com"
              className="block transition hover:zoom"
              target="_blank"
              rel="noopener noreferrer"
              title="Just Trees Online"
            >
              <Image className="" fluid={justtrees.childImageSharp.fluid} />
            </a>
          </div>
          <div className="p-4 lg:p-8 flex-initial w-1/2 lg:w-1/3">
            <a
              href="https://mrtoolpro.com"
              className="block transition hover:zoom"
              target="_blank"
              rel="noopener noreferrer"
              title="Mr. ToolPro"
            >
              <Image className="" fluid={mrtoolpro.childImageSharp.fluid} />
            </a>
          </div>
          <div className="p-4 lg:p-8 flex-initial w-1/2 lg:w-1/3">
            <a
              href="https://thetopmusicalinstruments.com"
              className="block transition hover:zoom"
              target="_blank"
              rel="noopener noreferrer"
              title="The Top Musical Instruments"
            >
              <Image className="" fluid={music.childImageSharp.fluid} />
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

export const useFamilyPetLover = () => {
  const { family, bizware, justtrees, mrtoolpro, music } = useStaticQuery(
    graphql`
      {
        family: file(relativePath: { eq: "family-pet-lover.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        bizware: file(relativePath: { eq: "just-bizware.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        justtrees: file(relativePath: { eq: "just-trees-online.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        mrtoolpro: file(relativePath: { eq: "mr-toolpro.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        music: file(relativePath: { eq: "top-musical-instruments.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  return { family, bizware, justtrees, mrtoolpro, music }
}
